import $api from "../http";
import {AxiosResponse} from 'axios';
//import {AuthResponse} from "../models/response/AuthResponse";

export default class AuthService {
    static async login(email, password) {
        return $api.post('/u/alogin', {email, password})
    }

    static async registration(email, password) {
        return $api.post('/u/registration', {email, password})
    }

    static async logout() {
        return $api.post('/u/logout')
    }

}

