import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import AuthService from "../services/auth-service";
import { ToastContainer, toast, ToastContentProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from '../components/dashboard';
import AuthPage from '../components/pages/auth-page';

export function errorNotify(errorData) {
    toast.error(errorData);
}

export function successNotify(errorData) {
    toast.success(errorData);
}

const IndexPage = () => {

    const {store} = useContext(Context);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            store.checkAuth()
        }
    }, [])
    async function getUsers() {
        try {
            const response = await AuthService.fetchUsers();
            setUsers(response.data);
        } catch (e) {
            //console.log(e);
        }
    }

    if (store.isLoading) {
        return <div>Загрузка...</div>
    }

    if (!store.isAuth) {
        return (
            <div>
                <ToastContainer />
                <AuthPage/>

            </div>
        );
    }
    return (
        <>
            <ToastContainer />
            <Dashboard />
        </>
    );
};

export default observer(IndexPage);

