import './editor.scss'

import { EditorContent, useEditor } from '@tiptap/react'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import React, {useState} from 'react'
import { useParams } from 'react-router-dom';
import {Button, Dropdown, DropdownButton, Tooltip, OverlayTrigger} from 'react-bootstrap';
import { API_URL } from '../http';
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'

export var contentEditor;

const MenuBar = ({ editor }) => {

  const {id} = useParams();
  console.log(id)

  if (!editor) {
    return null
  }
  const onUpdate = () => {
    contentEditor = editor.getHTML();
  }
  const onCreate = () => {
    if (id == null || id == undefined) {
      contentEditor = '<p>Создайте свой контент!</p>';
      editor.commands.setContent('<p>Создайте свой контент!</p>');
    }
    else {
      const getOneNews = async () => {
        const res = await fetch(
          API_URL + `/p/${id}`
        );
        const data = await res.json();
        contentEditor = data.content;
        editor.commands.setContent(data.content);
      };
      getOneNews();
    }
  }
  editor.on('create', onCreate);
  editor.on('update', onUpdate);

  return (
    <>
      <div className="mb-2">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              Размер шрифта для текста
            </Tooltip>
          }
        >
          <DropdownButton
            size="sm"
            variant='btn btn-outline-primary outline-btn-bl'
            title="Размер шрифта" 
            className="m-1 d-inline">
            <Dropdown.Item 
              onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
              className={editor.isActive('heading', { level: 1 }) ? 'is-active ' : ''}
            ><h1>Заголовок 1</h1></Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
              className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
            ><h2>Заголовок 2</h2></Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
              className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
            ><h3>Заголовок 3</h3></Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
              className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
            ><h4>Заголовок 4</h4></Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
              className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
            ><h5>Заголовок 5</h5></Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
              className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
            ><h6>Заголовок 6</h6></Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().setParagraph().run()}
              className={editor.isActive('paragraph') ? 'is-active' : ''}
            >Обычный текст</Dropdown.Item>
          </DropdownButton>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              <strong>Жирный шрифт</strong>
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant={editor.isActive('bold') ? 'btn btn-primary btn-bl' : 'btn btn-outline-primary outline-btn-bl'}
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'm-1 is-active' : 'm-1'}
          >
            <i className='bx bx-bold bx-fw' ></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              <i>Курсивный шрифт</i>
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant={editor.isActive('italic') ? 'btn btn-primary btn-bl' : 'btn btn-outline-primary outline-btn-bl'}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'm-1 is-active' : 'm-1'}
          >
            <i className='bx bx-italic bx-fw' ></i>
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              <u>Подчеркнутый шрифт</u>
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant={editor.isActive('underline') ? 'btn btn-primary btn-bl' : 'btn btn-outline-primary outline-btn-bl'}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={editor.isActive('underline') ? 'm-1 is-active' : 'm-1'}
          >
            <i className='bx bx-underline bx-fw' ></i>
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              <strike>Зачеркнутый шрифт</strike>
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant={editor.isActive('strike') ? 'btn btn-primary btn-bl' : 'btn btn-outline-primary outline-btn-bl'}
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={editor.isActive('strike') ? 'm-1 is-active' : 'm-1'}
          >
            <i className='bx bx-strikethrough bx-fw' ></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              Выбор цвета для текста
            </Tooltip>
          }
        >
          <DropdownButton
            size="sm"
            variant='btn btn-outline-primary outline-btn-bl'
            title="Цвет" 
            className="m-1 d-inline">
            <Dropdown.Item 
              onClick={() => editor.chain().focus().setColor('#B9F18D').run()}
            >Зеленый</Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().setColor('#F98181').run()}
            >Красный</Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().setColor('#FAF594').run()}
            >Желтый</Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().setColor('#70CFF8').run()}
            >Синий</Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().setColor('#808080').run()}
            >Серый</Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().unsetColor().run()}
            >Системный</Dropdown.Item>
          </DropdownButton>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              Выделить фрагмент текста
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant={editor.isActive('code') ? 'btn btn-primary btn-bl' : 'btn btn-outline-primary outline-btn-bl'}
            onClick={() => editor.chain().focus().toggleCode().run()}
            className={editor.isActive('code') ? 'm-1 is-active' : 'm-1'}
          >
            <i className='bx bx-code-alt bx-fw'></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              Форматировать текст
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant='btn btn-outline-primary outline-btn-bl'
            className="m-1" onClick={() => editor.chain().focus().unsetAllMarks().run()}>
            <i className='bx bx-refresh bx-fw' ></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              Форматировать список
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant='btn btn-outline-primary outline-btn-bl'
            className="m-1" onClick={() => editor.chain().focus().clearNodes().run()}>
            <i className='bx bx-list-minus bx-fw' ></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              Маркированный список
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant={editor.isActive('bulletList') ? 'btn btn-primary btn-bl' : 'btn btn-outline-primary outline-btn-bl'}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive('bulletList') ? 'm-1 is-active' : 'm-1'}
          >
            <i className='bx bx-list-ul bx-fw' ></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              Нумерованный список
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant={editor.isActive('orderedList') ? 'btn btn-primary btn-bl' : 'btn btn-outline-primary outline-btn-bl'}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={editor.isActive('orderedList') ? 'm-1 is-active' : 'm-1'}
          >
            <i className='bx bx-list-ol bx-fw' ></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              Поле для ввода кода
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant={editor.isActive('codeBlock') ? 'btn btn-primary btn-bl' : 'btn btn-outline-primary outline-btn-bl'}
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            className={editor.isActive('codeBlock') ? 'm-1 is-active' : 'm-1'}
          >
            <i className='bx bx-code-block bx-fw' ></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              Поле для ввода цитаты
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant={editor.isActive('blockquote') ? 'btn btn-primary btn-bl' : 'btn btn-outline-primary outline-btn-bl'}
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            className={editor.isActive('blockquote') ? 'm-1 is-active' : 'm-1'}
          >
            <i className='bx bxs-quote-right bx-fw' ></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              Сделать отступ вниз
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant='btn btn-outline-primary outline-btn-bl'
            className="m-1" onClick={() => editor.chain().focus().setHardBreak().run()}>
            <i className='bx bx-space-bar bx-fw' ></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              Отменить действие
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant='btn btn-outline-primary outline-btn-bl'
            className="m-1" onClick={() => editor.chain().focus().undo().run()}>
            <i className='bx bx-undo bx-fw' ></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              Повторить действие
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant='btn btn-outline-primary outline-btn-bl'
            className="m-1" onClick={() => editor.chain().focus().redo().run()}>
            <i className='bx bx-redo bx-fw' ></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip >
              Работа с таблицами
            </Tooltip>
          }
        >
          <DropdownButton
            size="sm"
            variant='btn btn-outline-primary outline-btn-bl'
            title="Таблицы" 
            className="m-1 d-inline">
            <Dropdown.Item 
              onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
            >Вставить таблицу</Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().addColumnBefore().run()}
            >Вставить столбец слева</Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().addColumnAfter().run()}
            >Вставить столбец справа</Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().deleteColumn().run()}
            >Удалить столбец</Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().addRowBefore().run()}
            >Вставить строку сверху</Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().addRowAfter().run()}
            >Вставить строку снизу</Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().deleteRow().run()}
            >Удалить строку</Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().deleteTable().run()}
            >Удалить таблицу</Dropdown.Item>


            <Dropdown.Item 
              onClick={() => editor.chain().focus().mergeCells().run()}
            >Объединить ячейки</Dropdown.Item>
            <Dropdown.Item 
              onClick={() => editor.chain().focus().splitCell().run()}
            >Разделить ячейки</Dropdown.Item>
          </DropdownButton>
        </OverlayTrigger>
      </div>
    </>
  )
}

export default () => {


  
  const editor = useEditor({
    extensions: [
      StarterKit, 
      Underline,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell, 
      TextStyle, 
      Color,
    ],

    content: ``,
  })

  return (
    <div>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </div>
  )
}