import React, {useState, useEffect, useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Breadcrumb, InputGroup, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Badge from 'react-bootstrap/Badge';
import { API_URL } from '../../http';
import axios from 'axios';
import {Context} from '../../index';
import successNotify from '../success-notify';





const TicketsPage = () => {

  const {store} = useContext(Context);
  const [items, setItems] = useState([]);
  const [archive, setArchive] = useState([]);

  async function changeStatus(_id, ticketStatus) {
    if (ticketStatus != 1) {
      return 0;
    }
    const formData = new FormData()
    formData.append('_id', _id)
    formData.append('ticketStatus', 2)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/t/changestatus', formData)
    .then(res => {
      getTickets();
      successNotify(`Начата работа с обращением №` + _id + `!`);
      addLog(`Начал работу с обращением № ${_id}`);
    })
    .catch(err => console.log(err));
  }

  async function addLog(description) {
    const formData = new FormData()
    formData.append('username', store.user.email)
    formData.append('description', description)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/l/create', formData)
    .then(res => {
    })
    .catch(err => console.log(err));
  }

  async function closeTicket(_id) {
    const formData = new FormData()
    formData.append('_id', _id)
    formData.append('ticketStatus', 3)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/t/changestatus', formData)
    .then(res => {
      getTickets();
      successNotify(`Обращение №` + _id + ` было закрыто!`);
      addLog(`Закрыл обращение № ${_id}`);
    })
    .catch(err => console.log(err));
  }

  async function archiveTicket(_id) {
    const formData = new FormData()
    formData.append('_id', _id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/t/changearhive', formData)
    .then(res => {
      getarchiveTickets();
      getTickets();
      successNotify(`Обращение №` + _id + ` было отправлено в архив!`);
      addLog(`Отправил в архив обращение № ${_id}`);
    })
    .catch(err => console.log(err));
  }

  const getTickets = async () => {
    const res = await fetch(
      API_URL + `/t`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setItems(data);
  };

  const getarchiveTickets = async () => {
    const res = await fetch(
      API_URL + `/t/archive`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setArchive(data);
  };

  useEffect(() => {
    getTickets();
  }, [items]);

  useEffect(() => {
    getarchiveTickets();
  }, [archive]);



    return (
      <div className="pt-2">
      <Breadcrumb>
        <Link className="breadcrumb-item" to="/">
          Главная
        </Link>
        <Breadcrumb.Item active>Заявки</Breadcrumb.Item>
      </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Заявки</h5>
          <Tab.Container>
            <Row>
              <Col sm={4}>
                <ListGroup className="bl-list-group">
                  {items.map((item) => {
                    return (
                      item.ticketStatus !=4 ?
                      <ListGroup.Item
                        onClick={() => changeStatus(item._id, item.ticketStatus)}
                        key={item._id}
                        href={`#` + item._id}
                        action
                        className="d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">
                          <div className={item.ticketStatus == 1 ? 'new-ticket fw-bold' : 'fw-bold'}>{item.ticketStatus == 1 ? <i className='bx bx-envelope bx-flashing bx-fw' ></i> : null} {item.name}</div>
                          {item.title}
                        </div>
                        <Badge 
                        
                          bg={item.ticketStatus == 1 ? `primary` : item.ticketStatus == 2 ? `light` : item.ticketStatus == 3 ? `danger` : null}
                          text={item.ticketStatus == 2 ? `dark` : null}
                          pill
                        >
                          {item.ticketStatus == 1 ? `Новое` : item.ticketStatus == 2 ? `В работе` : item.ticketStatus == 3 ? `Закрыто` : null}
                        </Badge>
                      </ListGroup.Item>
                      :
                      null
                    )
                  })}
                </ListGroup>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  {items.map((item) => {
                    return (
                    <Tab.Pane key={item._id} eventKey={`#` + item._id}>
                      <ListGroup>
                        <ListGroup.Item variant={item.ticketStatus != 3 ? `` : `dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Номер обращения</b>
                            {item._id}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item variant={item.ticketStatus != 3 ? `` : `dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Имя</b>
                            {item.name}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item variant={item.ticketStatus != 3 ? `` : `dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Телефон</b>
                            {item.phone}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item variant={item.ticketStatus != 3 ? `` : `dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Статус обращения</b>
                            {item.ticketStatus == 1 ? `Новое` : item.ticketStatus == 2 ? `В работе` : item.ticketStatus == 3 ? `Закрыто` : item.ticketStatus == 4 ? `В архиве` : null}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item variant={item.ticketStatus != 3 ? `` : `dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Дата обращения</b>
                            {item.ticketData}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item variant={item.ticketStatus != 3 ? `` : `dark`}>
                            <b>Текст обращения</b>
                            <p>{item.message}</p>
                        </ListGroup.Item>
                        <InputGroup className="mt-2">
                          {item.ticketStatus != 3 ?
                            <Button onClick={() => closeTicket(item._id)} variant="outline-primary" className="outline-btn-bl" id="button-addon2">
                            <i className='bx bx-fw bx-lock-alt' ></i> Закрыть обращение
                            </Button>
                          :null}
                          {item.ticketStatus != 4 ? 
                          <Button onClick={() => archiveTicket(item._id)} variant="outline-primary" className="outline-btn-bl" id="button-addon2">
                            <i className='bx bx-fw bx-book' ></i> Отправить в архив
                          </Button>
                          : null}
                        </InputGroup>
                      </ListGroup>
                    </Tab.Pane>
                    )
                  })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>



        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Архив заявок</h5>
          <Tab.Container>
            <Row>
              <Col sm={4}>
                <ListGroup className="bl-list-group">
                  {archive.map((item) => {
                    return (
                    <ListGroup.Item
                      onClick={() => changeStatus(item._id, item.ticketStatus)}
                      key={item._id}
                      href={`#` + item._id}
                      action
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className={item.ticketStatus == 1 ? 'new-ticket fw-bold' : 'fw-bold'}>{item.ticketStatus == 1 ? <i className='bx bx-envelope bx-flashing bx-fw' ></i> : null} {item.name}</div>
                        {item.title}
                      </div>
                      <Badge 
                      
                        bg={item.ticketStatus == 1 ? `primary` : item.ticketStatus == 2 ? `light` : item.ticketStatus == 3 ? `danger` : item.ticketStatus == 4 ? `warning` : null}
                        text={item.ticketStatus == 2 ? `dark` : null}
                        pill
                      >
                        {item.ticketStatus == 1 ? `Новое` : item.ticketStatus == 2 ? `В работе` : item.ticketStatus == 3 ? `Закрыто` : item.ticketStatus == 4 ? `В архиве` : null}
                      </Badge>
                    </ListGroup.Item>
                    )
                  })}
                </ListGroup>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  {archive.map((item) => {
                    return (
                    <Tab.Pane key={item._id} eventKey={`#` + item._id}>
                      <ListGroup>
                        <ListGroup.Item variant={item.ticketStatus != 4 ? `` : `dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Номер обращения</b>
                            {item._id}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item variant={item.ticketStatus != 4 ? `` : `dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Имя</b>
                            {item.name}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item variant={item.ticketStatus != 4 ? `` : `dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Телефон</b>
                            {item.phone}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item variant={item.ticketStatus != 4 ? `` : `dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Статус обращения</b>
                            {item.ticketStatus == 1 ? `Новое` : item.ticketStatus == 2 ? `В работе` : item.ticketStatus == 3 ? `Закрыто` : item.ticketStatus == 4 ? `В архиве` : null}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item variant={item.ticketStatus != 4 ? `` : `dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Дата обращения</b>
                            {item.ticketData}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item variant={item.ticketStatus != 4 ? `` : `dark`}>
                            <b>Текст обращения</b>
                            <p>{item.message}</p>
                        </ListGroup.Item>
                        <InputGroup className="mt-2">
                        </InputGroup>
                      </ListGroup>
                    </Tab.Pane>
                    )
                  })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>






      </div>
    );
};

export default observer(TicketsPage);