import React, {createContext} from 'react';
import App from './App';
import Store from "./stores/store";
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);


export const store = new Store();
export const Context = createContext({
    store,
})

root.render(
    <Context.Provider value={{
        store
    }}>
        <App />
    </Context.Provider>
);

