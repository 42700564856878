import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Breadcrumb} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import { API_URL } from '../../http';




const LogsPage = () => {

  const [logs, setLogs] = useState([]);

  const getLogs = async () => {
    const res = await fetch(
      API_URL + `/l/all`, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
     });
    const data = await res.json();
    setLogs(data);
  };

  useEffect(() => {
    getLogs();
  }, [setLogs]);

    return (
      <div className="pt-2">
      <Breadcrumb>
        <Link className="breadcrumb-item" to="/">
          Главная
        </Link>
        <Breadcrumb.Item active>Все события</Breadcrumb.Item>
      </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Все события</h5>
          <table className="admintable mb-2">
            <thead>
              <tr>
                <th>Дата и время</th>
                <th>Пользователь</th>
                <th>Действие</th>
              </tr>
            </thead>
            <tbody>
            {logs.map((logs) => {
              return (
                <tr key={logs._id}>
                  <td data-label="Дата и время">{logs.logDate}</td>
                  <td data-label="Пользователь">{logs.username}</td>
                  <td data-label="Действие">{logs.description}</td>
                </tr>
              )
            })}
            </tbody>
          </table> 
        </div>
      </div>
    );
};

export default observer(LogsPage);