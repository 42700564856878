import React from 'react';
import {observer} from "mobx-react-lite";
import {Breadcrumb, ListGroup} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import 'boxicons/css/boxicons.css';
import 'boxicons';




const SupportPage = () => {

    return (
      <div className="pt-2">
      <Breadcrumb>
        <Link className="breadcrumb-item" to="/">
          Главная
        </Link>
        <Breadcrumb.Item active>Поддержка</Breadcrumb.Item>
      </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-4">Поддержка</h5>
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
              <h5 className="pb-2 pt-2">Техническая поддержка</h5>
              <ListGroup>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>График работы</b>
                      ПН-ПТ с 10:00 до 19:00 (GMT+5)
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>Время обработки обращения</b>
                      До 72 часов
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>E-Mail</b>
                      support@bubble-lab.ru
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>Телефон</b>
                      +7 (912) 418 49 57
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
              <h5 className="pb-2 pt-2">Техническая поддержка продукта Bubble CMS</h5>
              <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>График работы</b>
                      ПН-ПТ с 10:00 до 19:00 (GMT+5)
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>Время обработки обращения</b>
                      До 72 часов
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>E-Mail</b>
                      cms@bubble-lab.ru
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>Телефон</b>
                      +7 (912) 418 49 57
                  </div>
                </ListGroup.Item>
            </div>
          </div>
          <div className="row pt-2">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
              <h5 className="pb-2 pt-2">Поддержка (Договор обслуживания сайта)</h5>
              <ListGroup>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>График работы</b>
                      ПН-ПТ с 10:00 до 19:00 (GMT+5)
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>Время обработки обращения</b>
                      До 24 часов
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>E-Mail</b>
                      clients@bubble-lab.ru
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>Телефон</b>
                      +7 (912) 418 49 57
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
              <h5 className="pb-2 pt-2">Администрирование</h5>
              <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>График работы</b>
                      ПН-ПТ с 10:00 до 19:00 (GMT+5)
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>Время обработки обращения</b>
                      До 48 часов
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>E-Mail</b>
                      administration@bubble-lab.ru
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <b>Телефон</b>
                      +7 (912) 418 49 57
                  </div>
                </ListGroup.Item>
            </div>
          </div>
        </div>
      </div>
    );
};

export default observer(SupportPage);