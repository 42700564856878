import React, { useContext } from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {Breadcrumb, Card, ListGroup, InputGroup, FormControl, Button, ButtonGroup, ListGroupItem, ButtonToolbar, Tab, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import 'boxicons/css/boxicons.css';
import 'boxicons';



const ProfilePage = () => {

  const {store} = useContext(Context);

    return (
      <div className="pt-2">
        <Breadcrumb>
          <Link className="breadcrumb-item" to="/">
            Главная
          </Link>
          <Breadcrumb.Item active>{store.user.username}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Мой профиль</h5>
          <Tab.Container defaultActiveKey="#profile">
            <Row>
              <Col sm={4}>
                <ListGroup className="bl-list-group">
                  <ListGroup.Item action href="#profile">
                    <i className='bx bx-user bx-fw' ></i> Основное
                  </ListGroup.Item>
                  <ListGroup.Item action href="#logs">
                    <i className='bx bx-briefcase-alt-2 bx-fw' ></i> Мои действия
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  <Tab.Pane eventKey="#profile">
                    <Card >
                      <i className='color-cms text-center display-1 bx bxs-user-circle'></i>
                        <Card.Body>
                          <Card.Title className="text-center">{store.user.username}</Card.Title>
                          <Card.Text className="text-center">
                          {store.user.email}
                          </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroupItem>

                            <InputGroup >
                            <FormControl
                              placeholder="Введите имя пользователя"
                              aria-label="Введите имя пользователя"
                              aria-describedby="basic-addon2"

                            />
                            <Button  variant="outline-primary" className="outline-btn-bl" id="button-addon2">
                              <i className='bx bx-fw bx-user-plus' ></i> Изменить имя
                            </Button>
                            </InputGroup>

                          </ListGroupItem>
                          <ListGroupItem>

                          <InputGroup>
                            <FormControl
                              placeholder="Введите E-Mail пользователя"
                              aria-label="Введите E-Mail пользователя"
                              aria-describedby="basic-addon2"

                            />
                            <Button variant="outline-primary" className="outline-btn-bl" id="button-addon2">
                              <i className='bx bx-fw bx-user-plus' ></i> Изменить E-Mail
                            </Button>
                            </InputGroup>

                          </ListGroupItem>
                          <ListGroupItem>


                            <ButtonToolbar aria-label="Toolbar with button groups" className="d-flex justify-content-center">
                              <ButtonGroup className="me-2" aria-label="First group">
                              <Button variant="outline-primary" className="outline-btn-bl" id="button-addon2">
                                    <i className='bx bx-fw bx-lock-open-alt' ></i> Изменить пароль
                                  </Button>
                              </ButtonGroup>
                              <ButtonGroup className="me-2" aria-label="Second group">
                              <Button variant="outline-primary" className="outline-btn-bl" id="button-addon2" >
                                    <i className='bx bx-fw bx-shield-alt-2' ></i> Снять все права
                                  </Button>
                              </ButtonGroup>
                              <ButtonGroup className="me-2" aria-label="Third group">
                              <Button variant="outline-primary" className="outline-btn-bl" id="button-addon2" >
                                    <i className='bx bx-fw bx-trash' ></i> Удалить пользователя
                                  </Button>
                              </ButtonGroup>
                            </ButtonToolbar>


                          </ListGroupItem>
                        </ListGroup>
                    </Card>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#logs">
    
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    );
};

export default observer(ProfilePage);