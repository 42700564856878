import React from 'react';
import {observer} from "mobx-react-lite";
import {Breadcrumb} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import 'boxicons/css/boxicons.css';
import 'boxicons';




const ModulePage = () => {

    return (
      <div className="pt-2">
      <Breadcrumb>
        <Link className="breadcrumb-item" to="/">
          Главная
        </Link>
        <Breadcrumb.Item active>Модуль</Breadcrumb.Item>
      </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Модуль</h5>
          <p className="pb-2">Модуль временно недоступен!</p>
        </div>
      </div>
    );
};

export default observer(ModulePage);