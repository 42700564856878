import React from 'react';
import {observer} from "mobx-react-lite";
import {Breadcrumb} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import 'boxicons/css/boxicons.css';
import 'boxicons';




const ErrorPage = () => {

    return (
      <div className="pt-2">
      <Breadcrumb>
        <Link className="breadcrumb-item" to="/">
          Главная
        </Link>
        <Breadcrumb.Item active>Ошибка!</Breadcrumb.Item>
      </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Ошибка!</h5>
          <p>Такой страницы не существует...</p>
        </div>
      </div>
    );
};

export default observer(ErrorPage);