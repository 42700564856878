import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import {Breadcrumb} from 'react-bootstrap';
import {render} from 'react-dom';
import {Link} from 'react-router-dom';
import '../../style.css';
import axios from 'axios';
import successNotify from '../success-notify';
import errorNotify from '../error-notify';
import {Context} from '../../index';
import {API_URL} from '../../http';

function AddNewsPage() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    addPages();
  };

  const [title, setTitle] = useState('')
  const [file, setFile] = useState(null)
  const [fullNews, setFullNews] = useState('')
  const [published, setPublished] = useState('false');

  const [imgTitle, setImgTitle] = useState('Выбрать файл...');
  const {store} = useContext(Context);

  const selectFile = e => {
      setFile(e.target.files[0])
      if (e.target.files[0] == null){
          setImgTitle(null);
      }
      else setImgTitle(e.target.files[0].name);
  }

  async function addLog(description) {
    const formData = new FormData()
    formData.append('username', store.user.email)
    formData.append('description', description)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/l/create', formData)
    .then(res => {
      console.log(res);
    })
    .catch(err => console.log(err));
  }

  var date = new Date();
  const pubDate =
    ("00" + date.getDate()).slice(-2) + "." +
    ("00" + (date.getMonth() + 1)).slice(-2) + "." +
    date.getFullYear() + " " +
    ("00" + date.getHours()).slice(-2) + ":" +
    ("00" + date.getMinutes()).slice(-2);

  const addPages = () => {
      if (title == '' || fullNews == '') {
          return errorNotify(`Необходимо заполнить все поля!`);
      }
      if (file == null) {
          setFile(null);
      }
      const formData = new FormData()
      formData.append('title', title)
      formData.append('img', file)
      formData.append('fullNews', fullNews)
      formData.append('published', published)
      formData.append('pubDate', pubDate)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/n/create', formData)
      .then(res => {
         successNotify(`Новость № ${res.data.news_id} была создана!`);
         addLog(`Создал новость № ${res.data.news_id}`);
      })
      .catch(err => console.log(err));

  }


  return (
    <div className="pt-2">
    <Breadcrumb>
      <Link className="breadcrumb-item" to="/">
        Главная
      </Link>
      <Link className="breadcrumb-item" to="/news">
        Новости
      </Link>
      <Breadcrumb.Item active>Добавить новость</Breadcrumb.Item>
    </Breadcrumb>
      <div className="shadow-sm p-4 bg-white rounded">
        <h5 className="pb-2">Добавить новость</h5>
        <Form noValidate validated={validated}>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Заголовок</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Заголовок новости"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
              <Form.Control.Feedback>
                Заголовок заполнен
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                  Заполните заголовок
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Выберите изображение</Form.Label>
              <Form.Control 
                required
                type="file"
                onChange={selectFile}
              />
              <Form.Control.Feedback>Изображение выбрано</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Выберите изображение
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomUsername">
              <Form.Label>Статус публикации</Form.Label>
              <Form.Select 
                aria-label="Default select example"
                defaultValue={published}
                onChange={e => setPublished(e.target.value)}
              >
                <option value={published}>Не публиковать новость</option>
                <option value="true">Опубликовать новость</option>
            </Form.Select>
            <Form.Control.Feedback>
              Статус публикации выбран
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Выберите статус публикации
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom04">
              <Form.Label>Новость</Form.Label>
              <Form.Control
                required
                as="textarea"
                style={{ height: '200px' }}
                value={fullNews}
                onChange={e => setFullNews(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Заполните полную новость
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Button className="btn-bl" onClick={handleSubmit}><i className='bx bx-fw bx-check bx-flashing' ></i> Создать новость</Button>
        </Form>
      </div>
    </div>
  );
}

export default (AddNewsPage);