import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import {Breadcrumb, Dropdown, DropdownButton, Button} from 'react-bootstrap';
import {render} from 'react-dom';
import {Link} from 'react-router-dom';
import '../../style.css';
import axios from 'axios';
import successNotify from '../success-notify';
import errorNotify from '../error-notify';
import { useParams } from 'react-router-dom';
import {API_URL} from '../../http';
import '../editor.scss'
import Editor, { contentEditor }from '../editor';

function EditStaticPage() {

  const {id} = useParams();
  const [title, setTitle] = useState('');
  const [titleFile, setTitleFile] = useState('');
  const [file, setFile] = useState(null);
  const [published, setPublished] = useState('false');
  const [items, setItems] = useState([]);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    addPages();
  };
  
  const page = {id};

  const getOneNews = async () => {
    const res = await fetch(
      API_URL + `/p/${id}`
    );

    const data = await res.json();
    if(data != null) {
        setTitle(data.title);
        setTitleFile(data.titleFile);
        setPublished(data.published);
        
    }
    setItems(data);
  };

  useEffect(() => {
    getOneNews();
  },[setItems]);



  const selectFile = e => {
      setFile(e.target.files[0])
  }

  var date = new Date();
  const pubDate =
    ("00" + date.getDate()).slice(-2) + "." +
    ("00" + (date.getMonth() + 1)).slice(-2) + "." +
    date.getFullYear() + " " +
    ("00" + date.getHours()).slice(-2) + ":" +
    ("00" + date.getMinutes()).slice(-2);

  const addPages = () => {
      if (title == '' || contentEditor == '') {
          return errorNotify(`Необходимо заполнить все поля!`);
      }
      if (file == null) {
          setFile(null);
      }
      const formData = new FormData()
      formData.append('title', title)
      formData.append('file', file)
      formData.append('content', contentEditor)
      formData.append('titleFile', titleFile)
      formData.append('published', published)
      formData.append('pubDate', pubDate)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/p/edit/' + id, formData)
      .then(res => {
         successNotify(`Страница № ${id} была изменена!`);
      })
      .catch(err => errorNotify(err));

  }


  if (items == null) return (
    <div>Отсутствует страница!</div>
  ) 

  
  return (
    <div className="pt-2">
    <Breadcrumb>
      <Link className="breadcrumb-item" to="/">
        Главная
      </Link>
      <Link className="breadcrumb-item" to="/pages">
        Страницы
      </Link>
      <Breadcrumb.Item active>Изменить страницу</Breadcrumb.Item>
    </Breadcrumb>
      <div className="shadow-sm p-4 bg-white rounded">
        <h5 className="pb-2">Изменить страницу</h5>
        <Form noValidate validated={validated}>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Заголовок</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Заголовок страницы"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
              <Form.Control.Feedback>
                Заголовок заполнен
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                  Заполните заголовок
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Выберите файл</Form.Label>
              <Form.Control 
                type="file"
                onChange={selectFile}
              />
              <Form.Control.Feedback>Прикреплять файл необязательно</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Прикреплять файл необязательно
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Название файла</Form.Label>
              <Form.Control
                type="text"
                placeholder="Название файла"
                value={titleFile}
                onChange={e => setTitleFile(e.target.value)}
              />
              <Form.Control.Feedback>
                Название файла заполнять без файла необязательно
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                  Заполните название файла
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomUsername">
              <Form.Label>Статус публикации</Form.Label>
              <Form.Select 
                aria-label="Default select example"
                defaultValue={published}
                onChange={e => setPublished(e.target.value)}
              >
                <option value={published}>Не публиковать страницу</option>
                <option value="true">Опубликовать страницу</option>
            </Form.Select>
            <Form.Control.Feedback>
              Статус публикации выбран
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Выберите статус публикации
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Editor />

          <Button className="btn-bl mt-2" onClick={handleSubmit}><i className='bx bx-fw bx-check bx-flashing' ></i> Изменить страницу</Button>
        </Form>
      </div>
    </div>
  );
}

export default (EditStaticPage);