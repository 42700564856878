import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import {Breadcrumb} from 'react-bootstrap';
import {render} from 'react-dom';
import {Link} from 'react-router-dom';
import '../../style.css';
import axios from 'axios';
import successNotify from '../success-notify';
import errorNotify from '../error-notify';
import { useParams } from 'react-router-dom';
import {API_URL} from '../../http';

function EditNewsPage() {
  const {id} = useParams();
  const [title, setTitle] = useState('')
  const [file, setFile] = useState(null)
  const [fullNews, setFullNews] = useState('')
  const [published, setPublished] = useState('false');
  const [imgTitle, setImgTitle] = useState(null);
  const [items, setItems] = useState([]);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    addPages();
  };

  const page = {id};

  const getOneNews = async () => {
      const res = await fetch(
        API_URL + `/n/${id}`
      );
      const data = await res.json();
      if(data != null) {
          setTitle(data.title);
          setFullNews(data.fullNews);
          setPublished(data.published);
      }

      setItems(data);
    };
  
    useEffect(() => {
      getOneNews();
    },[setItems]);

  const selectFile = e => {
      setFile(e.target.files[0])
      if (e.target.files[0] == null){
          setImgTitle(null);
      }
      else setImgTitle(e.target.files[0].name);
  }

  var date = new Date();
  const pubDate =
    ("00" + date.getDate()).slice(-2) + "." +
    ("00" + (date.getMonth() + 1)).slice(-2) + "." +
    date.getFullYear() + " " +
    ("00" + date.getHours()).slice(-2) + ":" +
    ("00" + date.getMinutes()).slice(-2);

  const addPages = () => {
      if (title == '' || fullNews == '') {
          return errorNotify(`Необходимо заполнить все поля!`);
      }
      if (file == null) {
          setFile(null);
      }
      const formData = new FormData()
      formData.append('title', title)
      formData.append('img', file)
      formData.append('fullNews', fullNews)
      formData.append('published', published)
      formData.append('pubDate', pubDate)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/n/edit/' + id, formData)
      .then(res => {
         successNotify(`Новость № ${id} была изменена!`);
      })
      .catch(err => console.log(err));

  }

  if (items == null) return (
    <div>Отсутствует страница!</div>
  ) 
  return (
    <div className="pt-2">
    <Breadcrumb>
      <Link className="breadcrumb-item" to="/">
        Главная
      </Link>
      <Link className="breadcrumb-item" to="/news">
        Новости
      </Link>
      <Breadcrumb.Item active>Изменить новость</Breadcrumb.Item>
    </Breadcrumb>
      <div className="shadow-sm p-4 bg-white rounded">
        <h5 className="pb-2">Изменить новость</h5>
        <Form noValidate validated={validated}>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Заголовок</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Заголовок новости"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
              <Form.Control.Feedback>
                Заголовок заполнен
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                  Заполните заголовок
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Выберите изображение</Form.Label>
              <Form.Control 
                required
                type="file"
                onChange={selectFile}
              />
              <Form.Control.Feedback>Изображение выбрано</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Выберите изображение
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomUsername">
              <Form.Label>Статус публикации</Form.Label>
              <Form.Select 
                aria-label="Default select example"
                defaultValue={published}
                onChange={e => setPublished(e.target.value)}
              >
                <option value={published}>Не публиковать новость</option>
                <option value="true">Опубликовать новость</option>
            </Form.Select>
            <Form.Control.Feedback>
              Статус публикации выбран
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Выберите статус публикации
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom04">
              <Form.Label>Полная новость</Form.Label>
              <Form.Control
                required
                as="textarea"
                style={{ height: '200px' }}
                value={fullNews}
                onChange={e => setFullNews(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Заполните полную новость
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Button className="btn-bl" onClick={handleSubmit}><i className='bx bx-fw bx-check bx-flashing' ></i> Изменить новость</Button>
        </Form>
      </div>
    </div>
  );
}

export default (EditNewsPage);