import React, { Component } from "react";
import Chart from "react-apexcharts";

export default function UserChart() {
  const series = [
    {
      name: 'Просмотров на сайте',
      data: [1, 5, 2, 3, 5, 2, 6, 3, 2]
    }, {
      name: 'Уникальные просмотры',
      data: [1, 2, 1, 2, 1, 2, 3, 3, 2]
    }
  ];
  const options = {
    chart: {
      locales: [{
        name: 'ru',
        options: {
          months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
          shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          toolbar: {
            exportToSVG: "Сохранить SVG",
            exportToPNG: "Сохранить PNG",
            exportToCSV: "Сохранить CSV",
            menu: 'Меню',
            selection: 'Выбор',
            selectionZoom: 'Выбор с увеличением',
            zoomIn: 'Увеличить',
            zoomOut: 'Уменьшить',
            pan: 'Перемещение',
            reset: 'Сбросить увеличение',
          }
        }
      }],
      defaultLocale: "ru",
      height: 350,
      type: 'area'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 1.5
    },
    xaxis: {
      type: 'category',
      categories: ["10.02.2022", "11.02.2022", "12.02.2022", "13.02.2022", "14.02.2022", "15.02.2022", "16.02.2022", "17.02.2022", "18.02.2022"]
    }
  };
  return (
    <div>
      <Chart options={options} type="area" series={series} height={350} />
    </div>
  );
}