import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API_URL } from "../http";

export default function SuperChart() {

  const [tickets, setTickets] = useState([]);

  const getCount = async () => {
    const res = await fetch(
      API_URL + `/t/count`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setTickets(data);
  };

  useEffect(() => {
    getCount();
  }, [setTickets]);


  const series = [tickets.newTickets == null || tickets.newTickets == undefined ? 1 : tickets.newTickets, tickets.atWorkTickets == null || tickets.atWorkTickets == undefined ? 1 : tickets.atWorkTickets, tickets.closedTickets == null || tickets.closedTickets == undefined ? 1 : tickets.closedTickets];
  const options = {
    labels: ['Новые заявки', 'На рассмотрении', 'Закрытые заявки'],
    colors:['#0fbcf9', '#575fcf', '#f53b57'],
    chart: {
      locales: [{
        name: 'ru',
        options: {
          months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
          shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          toolbar: {
            exportToSVG: "Сохранить SVG",
            exportToPNG: "Сохранить PNG",
            exportToCSV: "Сохранить CSV",
            menu: 'Меню',
            selection: 'Выбор',
            selectionZoom: 'Выбор с увеличением',
            zoomIn: 'Увеличить',
            zoomOut: 'Уменьшить',
            pan: 'Перемещение',
            reset: 'Сбросить увеличение',
          }
        }
      }],
      defaultLocale: "ru",
      width: 380,
      type: 'donut'
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      position: 'right',
      offsetY: 0,
      height: 230,
    }
  };

  return (
    <div>
      <Chart options={options} type="donut" series={series} width={380} />
    </div>
  );
}