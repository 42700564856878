import React, {useContext, useState} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {ReactComponent as LoginSVG} from '../../assets/login.svg';
import '../../assets/login.css';

const AuthPage = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {store} = useContext(Context);

    return (
        <div className="bubble-cms">
            <div className="bcms-auth-header">
                <h1>BUBBLE CMS</h1>
                <p>ПАНЕЛЬ УПРАВЛЕНИЯ</p>
            </div>
            <div className="bcms-auth-form">
                <div className="bcms-auth-login">
                    <label className="bcms-auth-label" htmlFor="email">Введите E-Mail</label>
                    <input
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        type="text"
                        name="email"
                        placeholder="cms@bubble-lab.ru"
                    />
                    <label className="bcms-auth-label" htmlFor="password">Введите пароль</label>
                    <input
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        type="password"
                        name="password"
                        placeholder="xxxxxxxx"
                    />
                    <button onClick={() => store.login(email, password)} className="bcms-auth-btn"><i className='bx bxs-chevron-right'></i></button>
                    <LoginSVG className="bcms-auth-particle-1" />
                    <div className="bcms-auth-particle-2"></div>
                </div>
            </div>
            <div className="bcms-auth-particles">
                <ul className="bcms-auth-particles-object">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </div>
    );
};

export default observer(AuthPage);
